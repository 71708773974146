import ultralightCopy from "copy-to-clipboard-ultralight";
import Vue from "../node_modules/vue/dist/vue.esm";

const fixtures = {
  snippets: [
    {
      id: 1,
      title: "docker ports mapping",
      content: `
          <code>-p HOST:CONTAINER</code> like:<br />
          <code>docker run -p 80:80 nginx</code>
          `,
      updated_at: "2022-01-22 12:22:10",
      tags: ["docker", "devops"],
    },
    {
      id: 2,
      title: "lambda in python",
      content: `<code>some_fn = lambda x: x + 1</code>
        or with multiple arguments <code>some_fn = lambda a, b: a + b</code>`,
      updated_at: "2022-01-22 12:22:10",
      tags: ["python", "programming"],
    },
    {
      id: 3,
      title: "pretty json in console",
      content: `<code>echo '{"foo": "bar", "example": "test"}' | json_pp</code>`,
      updated_at: "2022-01-22 12:22:10",
      tags: ["cli", "json", "bash"],
    },
    {
      id: 4,
      title: "clean docker images older than",
      content: `<code>docker image prune -a --filter "until=7d"</code>
        clean images older than 7 days. Other filters: <code>24h</code>`,
      updated_at: "2022-01-22 12:22:10",
      tags: ["docker", "cleanup", "devops"],
    },
    {
      id: 5,
      title: "foreach shortcut in python",
      content: "<code>[item.id for item in items]</code>",
      updated_at: "2022-01-22 12:22:10",
      tags: ["python", "programming"],
    },
  ],
};

const data = {
  snippets: {
    items: [...fixtures.snippets],
  },
  search_query: "",
};

var app = new Vue({
  el: "#app",
  data: data,
  methods: {
    search(event) {
      const query = event.target.value;
      const items = [...fixtures.snippets];
      data.snippets.items = items;
      if (query !== "") {
        const searchFor = new RegExp(`${query.split(" ").join("|")}`);
        data.snippets.items = items.filter(
          (snippet) => snippet.title.search(searchFor) > -1
        );
      }
      document.querySelectorAll("._card code").forEach((el) => {
        console.log('code el', el.innerHTML);
        el.addEventListener('click', () => {
          ultralightCopy(el.innerHTML);
        });
      });
    },
  },
});
